<template>
  <HomePage />
</template>

<script>
import HomePage from '../components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
html, body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
#app {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'hero-new', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2F4858;
  margin-top: 0;
}

h3 {
  margin: 0;
}

p {
  font-size: 14px;
  margin: 6px 0;
}
</style>
