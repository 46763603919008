<template>
  <div id="main" class="main">
    <Nav ref="nav" :agenciesForSearch="agencies" class="navbar" :search="navSearchEnabled" />
    <div class="page-top" ref="pagetop">
      <div class="main-area">
        <h1>Track restoration of transit service and ridership across the U.S.</h1>
        <Search :agencies="agencies" />
        <a href="#table-section" class="list-icon">Explore data for all{{ agencies.length > 0 ? ' ' + agencies.length : '' }} agencies</a>
      </div>
    </div>
    <div class="content-container">
      <p class="desc-p">This site visualizes data on the levels of transit service (vehicle hours of passenger service) and ridership (number of passenger trips) for {{ agencies.length > 0 ? ' ' + agencies.length : '' }} agencies in the US. Values are self-reported to the FTA by transit agencies. <a href="/about">Read more</a></p>
      <h2>State-by-state</h2>
      <div class="state-maps">
        <div class="map-container map-service">
          <h3>Service restored</h3>
          <p>Percentage of transit vehicle revenue hours operated in <strong>{{ monthName }}</strong> compared to before pandemic</p>
          <StatesMap 
              :agencies="agencies"
              variable="vrh"
              :colors="['#FFC700','#FFAB90','#E990B0','#BF58BB','#8E2F96']"></StatesMap>
        </div>
        <div class="map-container map-ridership">
        <h3>Ridership</h3>
        <p>Percentage of public transit trips taken by riders in <strong>{{ monthName }}</strong> compared to before the pandemic</p>
          <StatesMap 
              :agencies="agencies"
              variable="upt"
              :colors="['#C2F5DA','#7BE0C8','#47BEC6','#0D8CB4','#005E9A']"></StatesMap>
        </div>
      </div>
      <TableSection ref="table" :agencies="agencies" id="table-section" :stickTo="$refs.nav" :monthString="monthName"> </TableSection>
    </div>
  </div>
  <Footer />
</template>

<script>
import TableSection from './TableSection.vue'
import StatesMap from './StatesMap.vue'
import Search from './Search.vue'
import Nav from './Nav.vue'
import Footer from './Footer.vue'
import TransformMonthlyValues from '../util/transform-monthly-values.js'
import * as cacheValue from '../util/cache-value.js'

export default {
  name: 'HomePage',
  components: {
    TableSection,
    StatesMap,
    Search,
    Nav,
    Footer
  },
  data() {
    return {
      agencies: [],
      navSearchEnabled: false
    }
  },
  mounted() {
    fetch('https://ntd-monthly.storage.googleapis.com/agency-breakdown.json?v' + cacheValue())
      .then(response => response.json())
      .then(data => (this.agencies = data))

    var lastKnownScrollPosition = 0;
    var ticking = false;

    document.addEventListener('scroll', (e) => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if(lastKnownScrollPosition >= this.$refs.pagetop.getBoundingClientRect().bottom){
            this.navSearchEnabled = true
          } else {
            this.navSearchEnabled = false
          }
          ticking = false;
        });

        ticking = true;
      }
    });
  },
  computed: {
    monthName() {
      if(this.agencies.length < 1){ return "" }
      var maxMonth;
      this.agencies.forEach((agency) => {
        if(!maxMonth || agency.meta.max_month > maxMonth){
          maxMonth = agency.meta.max_month
        }
      })
  
      var date = (new Date(Date.parse(maxMonth)))
      date.setDate(date.getDate() + 10);
      return date.toLocaleDateString('en-EN',{ month: 'long', year: 'numeric' })
    }
  },
  watch: {
  }
}
</script>

<style>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.main {
  width: 100%;
  text-align: left;
}

.page-top {
  background: #2F4858;
  width: 100%;
  overflow: visible;
  text-align: center;
  border-top: 1px solid rgba(255,255,255,0.2);
}

.page-top a {
  text-decoration: underline;
  color: white;
  display: inline-block;
  margin-top: 8px;
  padding: 8px;
  border-radius: 4px;
}

.page-top a:hover {
  background-color: rgba(0,0,0,0.2);
}

a.list-icon {
  text-indent: 24px;
  background-image: url(/list_icon.svg);
  background-repeat: no-repeat;
  background-position: left 8px center;
}

.main-area {
  width: 100%;
  max-width: 624px;
  margin: 0 auto;
  padding: 48px 32px;
  box-sizing: border-box;
}

p.desc-p {
  margin-bottom: 48px;
  display: block;
}

p.desc-p a {
  color: #2F4858;
}

/* p.desc-p::first-letter {
  float: left;
  font-size: 36px;
  font-weight: 800;
  line-height: 0.65;
  margin: 7px 4px 0px 0;
}

.desc-p:after {
  content: "";
  display: block;
} */

h1 {
  color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.state-maps > .map-container {
  display: inline-block;
  width: calc(50% - 30px);
  margin-right: 60px;
  min-width: 300px;
  text-align: left;
  vertical-align: top;
}

.map-container .states-map {
  max-width: 380px;
}

.state-maps > .map-container:last-of-type {
  margin-right: 0
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1200;
}

.state-maps p {
  margin-bottom: 24px;
}

p.subtle {
  opacity: 0.8;
}

#table-section {
  scroll-margin-top: 128px;
}

h2 {
  margin-top: 24px;
}

@media(max-width: 816px){
  .state-maps > .map-container {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 72px;
  }

  .state-maps > .map-container:last-of-type {
    margin-bottom: 0;
  }
}

@media(max-width: 816px) and (min-width: 564px){
  .map-container .states-map {
    width: 100%;
    white-space: nowrap;
  }

  .states-map svg {
    display: inline-block;
    width: 380px;
  }

  .states-map .map-legend {
    display: inline-block;
    width: 120px;
    text-align: center;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: 32px;
  }

  .map-legend ul.map-legend-colors li, .map-legend span.legend-class, .map-legend span.legend-no-data {
      display: block;
      margin-bottom: 4px;
      margin-right: 0;
  }

  .map-legend span.legend-class:first-of-type {
    margin-bottom: 8px;
  }

  ul.map-legend-colors li:last-of-type {
    margin-bottom: 8px;
  }

  .map-legend span.legend-no-data {
    float: none;
    padding-top: 24px;
    margin-top: 24px;
    width: 100%;
    text-align: center;
  }

  .map-legend span.legend-no-data::after {
    left: 50%;
    right: auto;
    transform: translate3d(-21px,0,0);
  }
}
</style>
