<template>
  <div ref="main" v-bind:class='{ "states-map": true, "hovered": hoveredState }'>
    <StatesVector></StatesVector>
    <div class="map-legend">
        <div class="map-legend-content" v-if="classes.length >= 2">
            <span class="legend-class legend-start">{{ formatPercent(classes[0]) }}</span>
            <ul class="map-legend-colors">
                <li v-bind:key="color" v-for="color in colors" v-bind:style='{ backgroundColor: color }'></li>
            </ul>
            <span class="legend-class legend-end">{{ formatPercent(classes[classes.length - 1]) }}</span>
            <span class="legend-no-data">No data</span>
        </div>
    </div>
    <div class="map-tooltip" v-if="hoveredState && percentsByState" v-bind:style="{ 'top': hoveredStateTopY + 'px','left': hoveredStateLeftX + 'px' }">
       <span class="map-tooltip-title">{{ stateAbbreviations[hoveredState] }}</span>
       <span class="map-tooltip-value">{{ percentsByState[hoveredState] ? formatPercent(percentsByState[hoveredState]) : 'No data' }}</span>
    </div>
  </div>
</template>

<script>
import StatesVector from './StatesVector.vue'
import * as geostats from 'geostats'
import * as StateAbbreviations from '../util/state-abbreviations.js'

export default {
  name: 'StatesMap',
  components: {
    StatesVector
  },
  props: ['agencies','variable','colors'],
  data() {
    return {
        classes: [],
        hoveredState: null,
        percentsByState: null,
        stateAbbreviations: StateAbbreviations,
        hoveredStateLeftX: -1,
        hoveredStateTopY: -1
    }
  },
  mounted() {
    let main = this.$refs.main
    let states = main.querySelector("#states")
    this.updateData()

    var stateElements = main.querySelectorAll("#states > path")
    for(var i = 0; i < stateElements.length; i++){
        var element = stateElements[i];
        element.addEventListener("click",(e) => {
            var state = e.target.getAttribute("id");
            if(this.percentsByState){
                this.$emit('click-state',state)
            }
        })

        element.addEventListener("mousemove",(e) => {
            var state = e.target.getAttribute("id");
            var mainRect = main.getBoundingClientRect();

            if(this.hoveredState != state){
                var rect = e.target.getBoundingClientRect();
                
                // this.hoveredStateLeftX = rect.left + (rect.width / 2) - mainRect.left
                // this.hoveredStateTopY = rect.top - mainRect.top
                this.hoveredState = state
            }

            this.hoveredStateLeftX = e.clientX - mainRect.left
            this.hoveredStateTopY = e.clientY - mainRect.top - 8
        })

        element.addEventListener("mouseout",(e) => {
            var state = e.target.getAttribute("id");
            if(this.hoveredState == state){
                this.hoveredState = null
            }
        })
    }
  },
  methods: {
      formatPercent (percent) {
          return Math.floor(percent * 100) + "%"
      },
      updateData() {
        if(!this.agencies){ return }

        var valuesByState = {}
        this.agencies.forEach((agency) => {
            var state = agency.meta.state
            if(!valuesByState[state]){
                valuesByState[state] = {
                    val_pre_pandemic: 0,
                    val_for_max_month: 0
                }
            }

            valuesByState[state].val_pre_pandemic  += agency.values[this.variable].val_pre_pandemic
            valuesByState[state].val_for_max_month += agency.values[this.variable].val_for_max_month
        })

        var percentsByState = {}
        var percents = []
        Object.keys(valuesByState).forEach((state) => {
            var percent = Math.floor(100 * (valuesByState[state].val_for_max_month / valuesByState[state].val_pre_pandemic)) / 100
            percentsByState[state] = percent
            percents.push(Math.min(0.95,percent))
        })

        this.percentsByState = percentsByState

        if(percents.length < 1){ return }

        var series = new geostats(percents)
        var classes = series.getClassJenks(this.colors.length - 1)
        this.classes = classes.map((classVal) => {
            if(classVal >= 0.95){ classVal = 1 }
            return classVal
        })

        Object.keys(percentsByState).forEach((state) => {
            var element = this.$refs.main.querySelector("#" + state)

            var percentIndex = 0;
            classes.forEach((classVal, index) => {
                if(percentsByState[state] > classVal){
                    percentIndex = index
                }
            })

            if(element){
                element.style.fill = this.colors[percentIndex]
            }
        })
      }
  },
  computed: {

  },
  watch: {
      agencies() {
          this.updateData();
      },
      hoveredState() {
          this.$emit('hovered-state-change',this.hoveredState)
      }
  }
}
</script>

<style>
svg {
    width: 100%;
    height: auto;
}

.states-map {
    position: relative;
    overflow: visible;
}

.map-legend {
    text-align: left;
    margin-top: 24px;
    width: 100%;
}

.map-legend > * {
    display: inline-block;
}

.map-legend-content {
    width: 100%;
}

.map-legend ul.map-legend-colors {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    margin-right: 3px;
}

.map-legend ul.map-legend-colors li {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 3px;
    margin-left: 0;
    vertical-align: top;
}

.map-legend span.legend-class, .map-legend span.legend-no-data {
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    margin-right: 6px;
}

.map-legend span.legend-end {
    margin-left: 2px;
}

.map-legend span.legend-no-data {
    float: right;
    padding-right: 22px;
    position: relative;
}

.map-legend span.legend-no-data::after {
    display: block;
    width: 16px;
    height: 16px;
    background-color: #BECBD3;
    position: absolute;
    right: 0;
    top: 0;
    content: ' ';
    font-size: 0;
}

.map-tooltip {
    position: absolute;
    top: auto;
    right: auto;
    background: white;
    box-shadow: 0px 7px 24px rgba(0,0,0,0.1);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px 16px;
    pointer-events: none;
    transform: translate3d(-50%,-100%,0);
    white-space: nowrap;
    z-index: 1900;
    text-align: center;
}

.map-tooltip span {
    font-size: 14px;
    display: block;
}

.map-tooltip span.map-tooltip-title {
    font-weight: bold;
}

.states-map.hovered {
    /* cursor: pointer; */
}
</style>
